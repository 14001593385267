import React, {useLayoutEffect} from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import Img from "gatsby-image/withIEPolyfill";
// import { linkResolver } from 'gatsby-source-prismic-graphql';
import useMousetrap from "react-hook-mousetrap"

// import useSiteMetadata from '../hooks/use-site-metadata';
import { rootPath, showcaseItemPath } from '../utils/linkResolver';

import Layout from "../components/layout"
import {TikLink, Quote, TechList, TechListItem} from "../components/helper"

import SEO from "../components/seo"
import Share from "../components/share"
import logo from "../images/made-by-logo.svg";


const ShowcaseItem = props => {
  useMousetrap("left", () => { 
    navigate(showcaseItemPath(prevShowcaseitem._meta));
  });
  useMousetrap("right", () => { 
    navigate(showcaseItemPath(nextShowcaseitem._meta));
  });
  useLayoutEffect(() => {
    if (typeof window !== `undefined`) {
      const timer = setTimeout(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        window.scrollTo(0, 0.7*vw - 0.8*vh);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, []);

  if (!props.data) return null;
  const location = props.location;
  const data = props.data;
  const lang = props.pageContext.lang;
  const t = props.data.prismic.allShowcaseitempages.edges[0].node;

  const { siteUrl, twitterHandle } = props.data.site.siteMetadata;
  
  const fullURL = `${siteUrl}${props.location.pathname}`;
  const homepageData = props.data.prismic.allHomepages.edges[0].node;

  // load showcaseitem
  const showcaseitemUid = props.pageContext.uid;
  const showcaseitems = props.data.prismic.allShowcaseitems.edges.map(({node}) => {
    return node
  });
  const showcaseitem = showcaseitems.find((node) => {
    return node._meta.uid === showcaseitemUid;
  });
  if (!showcaseitem) return null;

  // load showcase
  const showcaseKey = showcaseitem.showcase && showcaseitem.showcase.key;
  const showcases = props.data.prismic.allShowcases.edges.map(({node}) => {
    return node
  });

  // find prev/next showcase (with item for link)
  const showcaseIndex = showcaseKey && showcases.findIndex(node => {
    return node.key === showcaseKey;  
  });
  const showcase = showcases[showcaseIndex];
  const showcaseShowcaseitems = showcaseitems.filter((node) => {
    return node.showcase && (node.showcase.key === showcaseKey)
  });
  const firstShowcase = showcases[0];
  const lastShowcase = showcases[showcases.length - 1];
  const prevShowcase = (showcaseIndex > 0 && showcases[showcaseIndex - 1]) || lastShowcase;
  const nextShowcase = (showcaseIndex > -1 && showcaseIndex < showcases.length && showcases[showcaseIndex + 1]) || firstShowcase;
  const prevShowcaseShowcaseitem = prevShowcase && showcaseitems.filter((node) => {
    return (node.showcase && node.showcase.key) === prevShowcase.key
  }).slice(-1)[0];
  const nextShowcaseShowcaseitem = nextShowcase && showcaseitems.filter((node) => {
    return (node.showcase && node.showcase.key) === nextShowcase.key
  })[0];
  
  // find prev/next showcase item
  const showcaseitemIndex = showcaseShowcaseitems.findIndex(node => {
    return node._meta.uid === showcaseitemUid;
  });
  const prevShowcaseitem = (
    showcaseitemIndex > 0 && showcaseShowcaseitems[showcaseitemIndex - 1]
  ) || prevShowcaseShowcaseitem;
  const nextShowcaseitem = (
    showcaseitemIndex < showcaseShowcaseitems.length && showcaseShowcaseitems[showcaseitemIndex + 1]
  ) || nextShowcaseShowcaseitem;

  // other data
  const links = showcaseitem.links.filter((link) => {
    return link.url;
  });
  // const technologiesBody = showcaseitem.body && showcaseitem.body.filter((item) => { return item.type === "technologies" })[0];
  // const technologies =  (technologiesBody || {}).fields;
  
  // const technologiesHeadline =  technologiesBody && ( technologiesBody.primary || {}).technology_headline;
  

  const linkHoverColor = (showcase && showcase.color === 'yellow') ? 'cyan' : 'yellow';
  const slideDotsColor = (showcase && showcase.color === 'magenta') ? 'yellow' : 'magenta';  
  
  // console.log('abcdefg', showcaseitem, showcaseitem.seo_ogimageSharp)
  return (
    <Layout location={location} data={data} lang={lang} hideFooter={true} activeKey={'showcases'}>
      <SEO 
        title={showcaseitem.seo_title || showcaseitem.headline} 
        description={showcaseitem.seo_description || (showcaseitem.text ? RichText.asText(showcaseitem.text) : '')}
        image={showcaseitem?.seo_ogimageSharp?.childImageSharp?.fixed?.src}
      />
      
        
      <section className="showcase-gallery">
        <div className="showcase-gallery-logo">
          <div className="section-logo">
            <Link className="made-by-logo" to={rootPath({lang: lang})}>
              <img alt="made.by Logo" src={logo} />
            </Link>
          </div>
        </div>

        <div className="showcase-gallery-bgs">
          <div className={`bg-fade bg-fade-yellow ${showcase && showcase.color === 'yellow' ? 'active': ''}`}></div>
          <div className={`bg-fade bg-fade-magenta ${showcase && showcase.color === 'magenta' ? 'active': ''}`}></div>
          <div className={`bg-fade bg-fade-cyan ${showcase && showcase.color === 'cyan' ? 'active': ''}`}></div>
        </div>

        <div className="showcase-gallery-content">
          <article className="showcase-item">

            <div className="showcase-item-image">
              {showcaseitem.image_top && <Img 
                fluid={showcaseitem.image_top.childImageSharp.fluid}
                alt={showcaseitem.image && showcaseitem.image.alt}
                />}
            </div>

            <div className="showcase-item-container">
              <div className="showcase-item-content">
                <Share className="showcase-share" linkClassName={`link-${linkHoverColor}`} url={fullURL} title={`made.by Tickaroo – ${showcaseitem.headline}`} twitterHandle={twitterHandle} />
                <div className="showcase-item-header">
                  {showcaseitem.logoSharp && (showcaseitem.logoSharp.childImageSharp ? <Img 
                      className="showcase-item-logo" 
                      fluid={showcaseitem.logoSharp.childImageSharp.fluid}
                      alt={showcaseitem.logo && showcaseitem.logo.alt}
                    />
                    :
                    <img 
                      className="showcase-item-logo" 
                      src={showcaseitem.logoSharp.publicURL}
                      alt={showcaseitem.logo && showcaseitem.logo.alt}
                    />
                  )}
                  <h1 className="showcase-item-headline">{showcaseitem.headline}</h1>
                </div>
                {showcaseitem.text && (
                  <div className="showcase-item-text">
                    <RichText render={showcaseitem.text} />
                  </div>
                )}
                {links.length > 0 && <div className="showcase-item-links">
                  {links.map((link, index) => 
                    <TikLink key={`showcase-item-link-${index}`} href={link.url.url} rel="nofollow" target="_blank">{link.displayText}</TikLink>
                  )}
                </div>}
              </div>

              { showcaseitem.body && showcaseitem.body.map((bodyitem, index) => {
                  if(bodyitem.type === "quote") {
                    return (
                      <div className={`showcase-item-quote bg-plain-yellow`} key={`showcase-item-quote-${index}`}>
                        { bodyitem.primary.headline1 && <h4 className="showcase-item-quote-headline">{bodyitem.primary.headline1}</h4>}
                        { bodyitem.primary.description && <div className="showcase-item-quote-description">{ bodyitem.primary.description }</div>}
                        { bodyitem.primary.quote && 
                          <Quote className="showcase-item-quote-quote" author={bodyitem.primary.author}>
                            {bodyitem.primary.quote}
                          </Quote>
                        }
                      </div>
                    )
                  } else if (bodyitem.type === "technologies") {
                    return (
                      <div className="showcase-item-tech" key={`showcase-item-tech-${index}`}>
                        <h4 className="showcase-item-tech-headline">{ (bodyitem.primary && bodyitem.primary.technology_headline) || t.technologies_headline_default}</h4>
                        <TechList className="showcase-item-tech-list">
                          { homepageData.technologies.map((technology, technologyIndex) => 
                            <TechListItem technology={technology.technology} key={`tech-list-item-${technologyIndex}`} />
                          )}
                        </TechList>
                        {/* <TechList className="showcase-item-tech-list">
                          { bodyitem.fields.map((technology) => 
                            <TechListItem technology={technology.technology} />
                          )}
                        </TechList> */}
                        <div className="tick"></div>
                      </div>
                    )
                  } else {
                    return null
                  }
                })
              }
                    
              { false && showcaseShowcaseitems.length > 1 &&
                <div className={`slide-pagination slide-pagination-${slideDotsColor}`}>
                  {showcaseShowcaseitems.map((slideItem) =>
                    <Link
                      key={'showcaseitem-' + slideItem._meta.uid}
                      to={ showcaseItemPath(slideItem._meta) }
                      className={`slide-pagination-item ${slideItem === showcaseitem ? 'active' : ''}`}
                    >
                    </Link>
                  )}
                </div>
              }
            </div>
          </article>

          {prevShowcaseitem && (
            <TikLink
              className={`showcase-item-pagination showcase-item-pagination-previous link-${linkHoverColor}`}
              to={ showcaseItemPath(prevShowcaseitem._meta) }
            ></TikLink>
          )}

          {nextShowcaseitem && (
            <TikLink
              className={`showcase-item-pagination showcase-item-pagination-next link-${linkHoverColor}`}
              to={ showcaseItemPath(nextShowcaseitem._meta) }
            ></TikLink>
          )}
                    
          {prevShowcase && prevShowcaseShowcaseitem && (
            <TikLink
              className={`showcase-pagination showcase-pagination-previous link-${linkHoverColor}`}
              to={ showcaseItemPath(prevShowcaseShowcaseitem._meta) }
            >
              <span className="showcase-pagination-text">{ prevShowcase.name }</span>
            </TikLink>
          )}

          {nextShowcase && nextShowcaseShowcaseitem && (
            <TikLink
              className={`showcase-pagination showcase-pagination-next link-${linkHoverColor}`}
              to={ showcaseItemPath(nextShowcaseShowcaseitem._meta) }
            >
              <span className="showcase-pagination-text">{ nextShowcase.name }</span>
            </TikLink>
          )}
        </div>

      </section>
        
    </Layout>
  );
};

export const query = graphql`
query ShowcaseItemQuery($lang: String = "de-de") {
  site {
    siteMetadata {
      title
      description
      author
      siteUrl
      twitterHandle
    }
  }
  prismic {
    ...fragmentPrismicLayout

    allShowcases(sortBy: sort_ASC, lang: $lang) {
      edges {
        node {
          sort
          key
          name
          color
        }
      }
    }

    allHomepages(lang: "de-de") {
      edges {
        node {
          technologies {
            technology {
              _linkType
              ... on PRISMIC_Technology {
                name
                #logo
                #logoSharp {
                #  extension
                #}
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }

    allShowcaseitempages(lang: $lang) {
      edges {
        node {
          technologies_headline_default
        }
      }
    }

    allShowcaseitems(sortBy: sort_ASC, lang: $lang) {
      edges {
        node {
          seo_title
          seo_description
          sort
          headline
          text
          logo
          logoSharp {
            childImageSharp {
              fluid(maxWidth: 180, maxHeight: 180, fit: INSIDE) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            extension
            publicURL
          }
          _meta {
            id
            uid
            type
            lang
          }
          showcase {
            ... on PRISMIC_Showcase {
              key
              name
            }
          }
          links {
            displayText
            url {
              __typename
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
          preview: imageSharp {
            childImageSharp {
              fixed(width: 1200, height: 1200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          seo_ogimage
          seo_ogimageSharp {
              childImageSharp {
                fixed(width: 1200, height: 630) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            
          
          image_top: imageSharp {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          image
          body {
            ... on PRISMIC_ShowcaseitemBodyTechnologies {
              type
              fields {
                technology {
                  ... on PRISMIC_Technology {
                    name
                    #logo
                    #logoSharp {
                    #  extension
                    #}
                    _meta {
                      uid
                    }
                  }
                }
              }
              primary {
                technology_headline
              }
            }
            ... on PRISMIC_ShowcaseitemBodyQuote {
              type
              label
              primary {
                author
                description
                headline1
                quote
              }
            }
          }
        }
      }
    }
  }
}
`;

export default ShowcaseItem;